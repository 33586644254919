import React from 'react';
import { Typography, Card, Divider } from 'antd';

import { Logo } from './Logo';
import { preset } from './styles';

type AuthPageLayoutProps = {
  children: React.ReactNode;
  title: string;
};

export const AuthPageLayout: React.FC<AuthPageLayoutProps> = ({
  title,
  children,
}) => {
  return (
    <div
      style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
      <div
        style={{
          textAlign: 'center',
          paddingTop: preset.spacing(15),
          paddingBottom: preset.spacing(8),
        }}>
        <Logo height={preset.spacing(7)} />
      </div>
      <div
        style={{
          flex: 1,
          width: '100%',
          maxWidth: 400,
          paddingLeft: preset.spacing(3),
          paddingRight: preset.spacing(3),
          marginLeft: 'auto',
          marginRight: 'auto',
        }}>
        <Card>
          <Typography.Title
            level={3}
            style={{ textAlign: 'center', marginBottom: preset.spacing(3) }}>
            {title}
          </Typography.Title>
          {children}
        </Card>
      </div>
      <footer
        style={{
          textAlign: 'center',
          paddingTop: preset.spacing(10),
          paddingBottom: preset.spacing(3),
        }}>
        <Typography.Link
          href="https://otso.ai/privacy"
          target="_blank"
          type="secondary">
          Privacy Policy
        </Typography.Link>
        <Divider type="vertical" />
        <Typography.Link
          href="https://otso.ai/terms"
          target="_blank"
          type="secondary">
          Terms and Conditions
        </Typography.Link>
      </footer>
    </div>
  );
};
