import React from 'react';
import { Layout, Row, Col } from 'antd';

import { OrgSelect } from './OrgSelect';
import { UserMenu } from './UserMenu';
import { Logo } from './Logo';
import { NavMenu, NavMenuRoute } from './NavMenu';
import { colors, preset } from './styles';

export type AppLayoutProps = {
  children?: React.ReactNode;
  className?: string;
  navMenu?: NavMenuRoute[] | React.ReactElement;
};

export const AppLayout: React.FC<AppLayoutProps> = ({
  children,
  className,
  navMenu,
}) => {
  return (
    <Layout className={className} style={{ minHeight: '100vh' }}>
      <Layout.Header
        style={{
          position: 'relative',
          zIndex: 1,
          backgroundColor: colors.WHITE,
          boxShadow: preset.BOX_SHADOW,
          height: preset.spacing(6),
          lineHeight: `${preset.spacing(6)}px`,
        }}>
        <Row gutter={preset.spacing(2)} wrap={false} style={{ height: '100%' }}>
          <Col>
            <Logo />
          </Col>
          <Col flex="auto">
            {Array.isArray(navMenu) ? <NavMenu routes={navMenu} /> : navMenu}
          </Col>
          <Col flex="240px">
            <OrgSelect />
          </Col>
          <Col>
            <UserMenu />
          </Col>
        </Row>
      </Layout.Header>
      <Layout.Content>{children}</Layout.Content>
    </Layout>
  );
};
