import React, { useState } from 'react';
import { Select } from 'antd';

import { useAuth } from './AuthProvider';
import { auth } from './firebase-export';
import { switchOrganisation } from './api';

export type OrgSelectProps = { className?: string };

export const OrgSelect: React.FC<OrgSelectProps> = ({ className }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { user } = useAuth();

  const organisations = user?.organisations || [];

  const isCurrentOrgExist = !!organisations.find(
    org => org.id === user?.currentOrg
  );

  const onOrgChange = async (newOrg: string) => {
    setIsLoading(true);
    await switchOrganisation(newOrg);
    // Force token refresh
    const { currentUser } = await auth();
    await currentUser?.getIdToken(true);
    setIsLoading(false);
    // Reload page
    window.location.replace('/');
  };

  return (
    <Select
      showSearch
      placeholder="Select an organisation"
      loading={isLoading}
      defaultValue={isCurrentOrgExist ? user?.currentOrg : undefined}
      onChange={onOrgChange}
      options={organisations.map(org => ({ label: org.name, value: org.id }))}
      optionFilterProp="label"
      notFoundContent="No organisation found"
      className={className}
      style={{ width: '100%' }}
    />
  );
};
