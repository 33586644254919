import React, { createContext, useContext, useRef, useCallback } from 'react';

import { User } from './types';
import { getUserById } from './api';
import { useAuth } from './AuthProvider';

type ProviderProps = { children: React.ReactNode };

type UserInfoStoreContextType = {
  getUserInfo: (userId: string) => Promise<User | null>;
};

type UserInfoById = { [userId: string]: User };

const initialState: UserInfoStoreContextType = {
  getUserInfo: async () => null,
};

export const UserInfoStoreContext = createContext<UserInfoStoreContextType>(
  initialState
);

export const useUserInfoStore = () => useContext(UserInfoStoreContext);

export const UserInfoStoreProvider: React.FC<ProviderProps> = ({
  children,
}) => {
  const { currentOrg } = useAuth();

  const orgUserInfoById: UserInfoById = (currentOrg?.users || []).reduce(
    (prev, user) => ({
      ...prev,
      [user.id]: user,
    }),
    {}
  );

  const userInfoStoreRef = useRef<UserInfoById>(orgUserInfoById);

  const getUserInfo = useCallback(
    async (userId: string): Promise<User | null> => {
      if (userInfoStoreRef.current?.[userId]) {
        return userInfoStoreRef.current[userId];
      }
      try {
        const { data: userInfo } = await getUserById(userId);
        if (userInfo) {
          userInfoStoreRef.current[userId] = userInfo;
        }
        return userInfo;
      } catch {
        return null;
      }
    },
    []
  );

  return (
    <UserInfoStoreContext.Provider value={{ getUserInfo }}>
      {children}
    </UserInfoStoreContext.Provider>
  );
};
