import { Organisation, TAllActionValues } from './../types/index';
import {
  getCurrentUserByUserId,
  getCurrentUserOrgsByUserId,
  getOrgUsers,
} from '../api';
import { auth } from '../firebase-export';

export const getCurrentUserData = async (
  currentOrgSlug: string
): Promise<{
  createdAt: string;
  firstName: string;
  lastName: string;
  email: string;
  organisations: Organisation[];
  actions: { [key in TAllActionValues]: boolean } | null;
} | null> => {
  const currentUser = auth().currentUser;
  if (!currentUser) return null;

  // Get basic user info
  const { data: userData = {} } = await getCurrentUserByUserId();

  // Get user orgs
  // If product is undefined (e.g. otso-auth), get all orgs
  // Otherwise, get product based orgs (e.g. insight, data)
  const { data: userOrgs = [] } = await getCurrentUserOrgsByUserId();

  const { data: currentOrgUsers = [] } = await getOrgUsers(currentOrgSlug);

  return {
    ...userData,
    organisations: userOrgs.map((userOrg: Organisation) => {
      if (userOrg.id === currentOrgSlug)
        return { ...userOrg, users: currentOrgUsers };
      return userOrg;
    }),
  };
};
