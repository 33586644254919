import { sessionLogout } from './api';
import { auth } from './firebase-export';
import { devLog } from './helpers';

export const logout = async (): Promise<void> => {
  try {
    await sessionLogout();
  } catch (error) {
    devLog(error);
  } finally {
    await auth().signOut();
  }
};
