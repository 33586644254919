import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/storage';

export const initFirebase = (firebaseConfig: Record<string, string>) => {
  firebase.initializeApp(firebaseConfig);
};

export const auth = firebase.auth;

export const app = firebase.app;
