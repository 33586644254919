import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Menu } from 'antd';
import { TAllActionValues } from './types';
import { useAuth } from './AuthProvider';

export type NavMenuRoute =
  | { title: string; path: string; requiredAction?: TAllActionValues }
  | {
    title: string;
    routes: {
      title: string;
      path: string;
      requiredAction?: TAllActionValues;
    }[];
    requiredAction?: TAllActionValues;
  };

type Props = { routes: NavMenuRoute[]; className?: string };

export const NavMenu: React.FC<Props> = ({ routes, className }) => {
  const { pathname } = useLocation();

  const { user } = useAuth();

  return (
    <Menu
      mode="horizontal"
      selectedKeys={[pathname]}
      className={className}
      style={{ borderBottomColor: 'transparent' }}>
      {routes
        .filter(
          route =>
            !route?.requiredAction || user?.actions?.[route.requiredAction]
        )
        .map(route =>
          'routes' in route ? (
            <Menu.SubMenu key={route.title} title={route.title}>
              {route.routes
                .filter(
                  childRoute =>
                    !childRoute.requiredAction ||
                    user?.actions?.[childRoute.requiredAction]
                )
                .map(childRoute => (
                  <Menu.Item key={childRoute.path}>
                    <Link to={childRoute.path}>{childRoute.title}</Link>
                  </Menu.Item>
                ))}
            </Menu.SubMenu>
          ) : (
            <Menu.Item key={route.path}>
              <Link to={route.path}>{route.title}</Link>
            </Menu.Item>
          )
        )}
    </Menu>
  );
};
