import React from 'react';

import { AuthProvider } from './AuthProvider';
import { AuthRoutes, AuthRoutesProps } from './AuthRoutes';
import { AppLayout, AppLayoutProps } from './AppLayout';
import { ThemeProvider } from './ThemeProvider';
import { UserInfoStoreProvider } from './UserInfoStoreProvider';
import { AccessBoundary } from './AccessBoundary';

export interface AuthWrapperProps extends AuthRoutesProps, AppLayoutProps {
  children?: React.ReactNode;
  layout?: boolean;
  primaryColor?: string;
  logo?: React.ReactNode | string;
}

export const AuthWrapper: React.FC<AuthWrapperProps> = ({
  children,
  layout = true,
  customRoutes,
  className,
  primaryColor,
  logo,
  navMenu,
}) => {
  return (
    <ThemeProvider primaryColor={primaryColor} logo={logo}>
      <AuthProvider>
        <UserInfoStoreProvider>
          <AuthRoutes customRoutes={customRoutes}>
            <AccessBoundary>
              {layout ? (
                <AppLayout navMenu={navMenu} className={className}>
                  {children}
                </AppLayout>
              ) : (
                children
              )}
            </AccessBoundary>
          </AuthRoutes>
        </UserInfoStoreProvider>
      </AuthProvider>
    </ThemeProvider>
  );
};
