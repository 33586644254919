// Import libraries
import firebase from 'firebase/app';

// Product keys
export type ProductKey = 'insight' | 'data';

// Custom claim type
export type CustomClaims = {
  currentOrg?: string;
  isSuper?: boolean;
  timezone?: string;
};

// Organisation type
export type Organisation = {
  name: string;
  logo?: string | null;
  timezone?: string;
  createdAt: string;
  id: string;
  users: User[];
};

// User type
export type User = {
  id: string;
  firstName: string;
  lastName: string;
  createdAt: string;
  organisations: Organisation[];
  // Firebase User
  displayName: string | null;
  email: string | null;
  photoURL: string | null;
  actions:
    | {
        [key in TAllActionValues]: boolean;
      }
    | null;
} & CustomClaims;

// Firebase Errors
export type FirebaseAuthError = firebase.auth.Error;
export type FirebaseStorageError = firebase.storage.FirebaseStorageError;

export type Action = {
  actionKey: string;
  shortDescription: string;
  longDescription: string;
  group?: string;
};

export type TAllActionValues =
  | TInsightActionValue
  | TDataActionValue
  | TAuthActionValue;

export type TAllActionKeys =
  | TInsightActionKey
  | TDataActionKey
  | TAuthActionKey;

// Insight
export enum InsightActions {
  'viewAllProjects' = 'insight.viewAllProjects',
  'createProject' = 'insight.createProject',
  'manageProjects' = 'insight.manageProjects',
  'viewDashboards' = 'insight.viewDashboards',
  'createDashboards' = 'insight.createDashboards',
  'manageDashboards' = 'insight.manageDashboards',
  'viewDocuments' = 'insight.viewDocuments',
  'viewLocationPage' = 'insight.viewLocationPage',
  'manageAlerts' = 'insight.manageAlerts',
  'downloadDocuments' = 'insight.downloadDocuments',
}
export type TInsightActionKey = keyof typeof InsightActions;
export type TInsightActionValue = typeof InsightActions[TInsightActionKey];
export interface InsightAction extends Action {
  actionKey: TInsightActionValue;
  group: 'Projects' | 'Dashboards' | 'Data Analysis' | 'Other';
}

// Data
export enum DataActions {
  'manageFiles' = 'data.manageFiles',
  'manageDatasets' = 'data.manageDatasets',
  'manageIntegrations' = 'data.manageIntegrations',
}
export type TDataActionKey = keyof typeof DataActions;
export type TDataActionValue = typeof DataActions[TDataActionKey];
export interface DataAction extends Action {
  actionKey: TDataActionValue;
}

// Auth
export enum AuthActions {
  'manageOrganisationUsers' = 'auth.manageOrganisationUsers',
}
export type TAuthActionKey = keyof typeof AuthActions;
export type TAuthActionValue = typeof AuthActions[TAuthActionKey];
export interface AuthAction extends Action {
  actionKey: TAuthActionValue;
}

export const INSIGHT_ACTIONS: InsightAction[] = [
  {
    actionKey: InsightActions.viewAllProjects,
    shortDescription: 'Access all Projects',
    longDescription:
      'User can access all Insight Projects within the organisation without being explicitly added to them.',
    group: 'Projects',
  },
  {
    actionKey: InsightActions.createProject,
    shortDescription: 'Create Projects',
    longDescription: 'User can create new Insight Projects',
    group: 'Projects',
  },
  {
    actionKey: InsightActions.manageProjects,
    shortDescription: 'Manage projects',
    longDescription:
      'Manage Insight Project settings that the user has access to. This includes editing datasets, user access, duplicating and deleting projects.',
    group: 'Projects',
  },
  {
    actionKey: InsightActions.viewDashboards,
    shortDescription: 'View Dashboards',
    longDescription: 'View project dashboards',
    group: 'Dashboards',
  },
  {
    actionKey: InsightActions.viewLocationPage,
    shortDescription: 'View Location page',
    longDescription: "View project's location page",
    group: 'Dashboards',
  },
  {
    actionKey: InsightActions.createDashboards,
    shortDescription: 'Create Dashboards',
    longDescription: 'Create new Insight Dashboards',
    group: 'Dashboards',
  },
  {
    actionKey: InsightActions.manageDashboards,
    shortDescription: 'Manage Dashboards',
    longDescription: 'Edit and Delete Insight Dashboards',
    group: 'Dashboards',
  },
  {
    actionKey: InsightActions.viewDocuments,
    shortDescription: 'View individual documents',
    longDescription:
      'Ability to view individual documents through chart drilldown and on the explore page.',
    group: 'Data Analysis',
  },
  {
    actionKey: InsightActions.manageAlerts,
    shortDescription: 'Manage Alerts',
    longDescription: 'Create, edit and delete dataset alerts',
    group: 'Data Analysis',
  },
  {
    actionKey: InsightActions.downloadDocuments,
    shortDescription: 'Download Documents',
    longDescription: 'Ability to download raw documents from the explore page',
    group: 'Data Analysis',
  },
];

export const DATA_ACTIONS: DataAction[] = [
  {
    actionKey: DataActions.manageFiles,
    shortDescription: 'Manage Files',
    longDescription: 'Create, edit and delete Files',
  },
  {
    actionKey: DataActions.manageDatasets,
    shortDescription: 'Manage Datasets',
    longDescription: 'Create, edit and delete Datasets',
  },
  {
    actionKey: DataActions.manageIntegrations,
    shortDescription: 'Manage Integrations',
    longDescription: 'Create, edit and delete Integrations',
  },
];

export const AUTH_ACTIONS: AuthAction[] = [
  {
    actionKey: AuthActions.manageOrganisationUsers,
    shortDescription: 'Manage Users',
    longDescription: 'Create, edit and remove users within the organisation',
  },
];
