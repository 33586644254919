import React, { useState } from 'react';
import { Form, Input, Button, message } from 'antd';
import { Link } from 'react-router-dom';

import { auth } from '../../firebase-export';
import { AuthPageLayout } from '../../AuthPageLayout';
import { preset } from '../../styles';

const FORGOT_PASSWORD_RESPONSE_MESSAGE =
  'We will send a new password to the specified email if a matching account is found in our system.';

export const ForgotPassword: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isResetEmailSent, setIsResetEmailSent] = useState<boolean>(false);

  const onForgotPassword = async (values: { email: string }) => {
    setIsLoading(true);
    try {
      const formattedEmail = values.email.trim();
      await auth().sendPasswordResetEmail(formattedEmail);
      message.success(FORGOT_PASSWORD_RESPONSE_MESSAGE);
      setIsResetEmailSent(true);
    } catch (error) {
      if (error.code === 'auth/user-not-found') {
        message.success(FORGOT_PASSWORD_RESPONSE_MESSAGE);
        setIsResetEmailSent(true);
      } else {
        message.error(error.message);
      }
    }
    setIsLoading(false);
  };

  return (
    <AuthPageLayout title="Forgot password">
      <Form layout="vertical" onFinish={onForgotPassword}>
        <Form.Item
          label="Email Address"
          name="email"
          rules={[
            {
              required: true,
              whitespace: true,
              message: 'Please input your email',
            },
          ]}>
          <Input />
        </Form.Item>
        <Form.Item style={{ marginBottom: preset.spacing(2) }}>
          <Button
            block
            loading={isLoading}
            disabled={isResetEmailSent}
            type="primary"
            htmlType="submit">
            {isResetEmailSent ? 'Sent' : 'Send Reset Link'}
          </Button>
        </Form.Item>
        <Form.Item style={{ marginBottom: 0 }}>
          <Button block type="link">
            <Link to="/login">Back to Login</Link>
          </Button>
        </Form.Item>
      </Form>
    </AuthPageLayout>
  );
};
