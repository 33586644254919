import { FirebaseAuthError } from './types';

export const getFirebaseLoginErrMsg = (error: FirebaseAuthError): string => {
  if (!error) return '';
  if (error.code === 'auth/popup-closed-by-user') {
    return '';
  }
  if (
    error.code === 'auth/admin-restricted-operation' ||
    error.code === 'auth/user-not-found'
  ) {
    return "Account doesn't exist. Please log in with another method or contact support.";
  }
  return error.message;
};

export const devLog = (error: any) => {
  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};
