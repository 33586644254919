import { authAPI, initAuthAPI, getAPIErrMsg } from './helpers';

// Exchanges a session cookie for a firebase custom auth token
export const getAuthToken = async (): Promise<string> => {
  const res: {
    data: { customToken: string };
  } = await authAPI.get('/auth/token', { withCredentials: true });
  return res.data.customToken;
};

// Invalidates all firebase tokens and removes the session cookie from the browser
export const sessionLogout = async () => {
  return authAPI.get('/session/logout', { withCredentials: true });
};

// Get a session cookier and set it in the browser
export const sessionLogin = async () => {
  return authAPI.get('/session/login', { withCredentials: true });
};

export const switchOrganisation = async (newOrganisationSlug: string) => {
  return authAPI.get(`/organisations/switch/${newOrganisationSlug}`);
};

export const getCurrentUserByUserId = async () => {
  return authAPI.get('/users/me');
};

export const getCurrentUserOrgsByUserId = async () => {
  return authAPI.get('/users/me/orgs');
};

export const getUserById = async (userId: string) => {
  return authAPI.get(`/users/${userId}`);
};

export const getOrgUsers = async (orgSlug: string) => {
  return authAPI.get(`/organisations/${orgSlug}/users`);
};

export { initAuthAPI, getAPIErrMsg };
