import React, { createContext, useContext } from 'react';

import { colors } from './styles';

export type ThemeProviderProps = {
  children?: React.ReactNode;
  primaryColor?: string;
  logo?: React.ReactNode | string;
};

type ThemeContextType = {
  primaryColor?: string;
  logo?: React.ReactNode | string;
};

const initialState: ThemeContextType = { primaryColor: colors.PRIMARY };

const ThemeContext = createContext<ThemeContextType>(initialState);

export const useTheme = () => useContext(ThemeContext);

export const ThemeProvider: React.FC<ThemeProviderProps> = ({
  children,
  primaryColor,
  logo,
}) => {
  return (
    <ThemeContext.Provider
      value={{
        primaryColor: primaryColor || initialState.primaryColor,
        logo,
      }}>
      {children}
    </ThemeContext.Provider>
  );
};
