import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Typography, Skeleton, message } from 'antd';
import { useHistory, useLocation, Link } from 'react-router-dom';
import { parse } from 'query-string';

import { auth } from '../../firebase-export';
import { AuthPageLayout } from '../../AuthPageLayout';
import { useTheme } from '../../ThemeProvider';
import { preset } from '../../styles';

export const ResetPassword: React.FC = () => {
  const [email, setEmail] = useState<string | null>(null);
  const [isVerifying, setIsVerifying] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const history = useHistory();
  const location = useLocation();

  const { primaryColor } = useTheme();

  const { oobCode }: { oobCode?: string } = parse(location.search);

  useEffect(() => {
    const verifyPasswordResetCode = async (code: string) => {
      try {
        const userEmail = await auth().verifyPasswordResetCode(code);
        setEmail(userEmail);
      } catch (error) {
        setEmail(null);
      }
      setIsVerifying(false);
    };

    if (oobCode) {
      verifyPasswordResetCode(oobCode);
    } else {
      setIsVerifying(false);
      setEmail(null);
    }
  }, [oobCode]);

  const onResetPassword = async (values: { password: string }) => {
    if (oobCode) {
      setIsLoading(true);
      try {
        await auth().confirmPasswordReset(oobCode, values.password);
        message.success('Your password has been reset successfully');
        history.push('/login');
      } catch (error) {
        setIsLoading(false);
        message.error(error.message);
      }
    }
  };

  return (
    <AuthPageLayout title="Reset your password">
      {isVerifying ? (
        <Skeleton active />
      ) : email ? (
        <Typography.Paragraph>
          Enter your new password for{' '}
          <span style={{ color: primaryColor }}>{email}</span>
        </Typography.Paragraph>
      ) : (
        <Typography.Paragraph>
          Your request to reset your password has expired or the link has
          already been used. Please try resetting your password again{' '}
          <Link to="/forgot-psw">here</Link>.
        </Typography.Paragraph>
      )}
      {!isVerifying && !!email && (
        <Form layout="vertical" onFinish={onResetPassword}>
          <Form.Item
            label="New password (8+ characters)"
            name="password"
            hasFeedback
            rules={[
              { required: true, message: 'Please input your new password' },
              {
                min: 8,
                message: 'Password must have 8 or more characters',
              },
            ]}>
            <Input.Password />
          </Form.Item>
          <Form.Item style={{ marginBottom: preset.spacing(2) }}>
            <Button block loading={isLoading} type="primary" htmlType="submit">
              Reset Password
            </Button>
          </Form.Item>
          <Form.Item style={{ marginBottom: 0 }}>
            <Button block type="link">
              <Link to="/login">Back to Login</Link>
            </Button>
          </Form.Item>
        </Form>
      )}
    </AuthPageLayout>
  );
};
