import React, { useEffect } from 'react';

import { AuthLoading } from '../../components';
import { logout } from '../../logout';

export const Logout: React.FC = () => {
  useEffect(() => {
    logout();
  }, []);

  return <AuthLoading />;
};
