import axios, { AxiosInstance, AxiosError } from 'axios';

import { auth } from '../firebase-export';
import { ProductKey } from '../types';
import { devLog } from '../helpers';

let authAPI: AxiosInstance = axios.create();

export const getCurrentUserToken = async (): Promise<string | null> => {
  const { currentUser } = await auth();
  if (currentUser) return currentUser.getIdToken();
  return null;
};

export const initAuthAPI = (authAPIHost: string, productKey?: ProductKey) => {
  authAPI = axios.create({
    baseURL: authAPIHost,
    headers: {
      ...(productKey ? { product: productKey } : {}),
      Accept: 'application/json',
    },
  });

  authAPI.interceptors.request.use(async config => {
    const idToken = await getCurrentUserToken();
    return idToken
      ? {
          ...config,
          headers: {
            ...config.headers,
            Authorization: `JWT ${idToken}`,
          },
        }
      : config;
  });
};

export const getAPIErrMsg = (
  err: AxiosError<{ logId: string; name: string; message: string }>,
  fallback = 'Internal Server Error'
): string => {
  devLog(err);
  return err.response?.data?.message || fallback;
};

export { authAPI };
