import React from 'react';
import {
  BrowserRouter,
  Switch,
  Route,
  Redirect,
  RouteProps,
} from 'react-router-dom';
import { parse, parseUrl, stringify } from 'query-string';

import { useAuth } from './AuthProvider';
import { Login, Logout, ForgotPassword, ActionCode } from './pages';

const PublicRoute: React.FC<RouteProps> = ({ children, ...restProps }) => {
  const { authed } = useAuth();
  return (
    <Route
      {...restProps}
      render={({ location }) => {
        if (!authed) return children;

        const { continueUrl, org } = parse(location.search);

        const parsedContinueUrl = parseUrl(
          typeof continueUrl === 'string' ? continueUrl : ''
        );

        const redirectPathname = parsedContinueUrl.url || '/';

        const redirectSearch = stringify({
          ...parsedContinueUrl.query,
          ...(org ? { org } : {}),
        });

        return (
          <Redirect
            to={{
              pathname: redirectPathname,
              search: redirectSearch,
            }}
          />
        );
      }}
    />
  );
};

const NO_REDIRECT_ROUTES = ['/logout'];

const PrivateRoute: React.FC<RouteProps> = ({ children, ...restProps }) => {
  const { authed } = useAuth();
  return (
    <Route
      {...restProps}
      render={({ location }) =>
        authed ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              search: NO_REDIRECT_ROUTES.includes(location.pathname)
                ? ''
                : stringify({
                    continueUrl: `${location.pathname}${location.search}`,
                  }),
            }}
          />
        )
      }
    />
  );
};

export type PublicRouteItem = {
  path: string;
  component: React.ComponentType;
};

export type CustomRouteItem = {
  path: string;
  component: React.ComponentType;
};

const DEFAULT_ROUTES: PublicRouteItem[] = [
  { path: '/login', component: Login },
  { path: '/forgot-psw', component: ForgotPassword },
  { path: '/action-code', component: ActionCode },
];

export type AuthRoutesProps = {
  children?: React.ReactNode;
  customRoutes?: CustomRouteItem[];
};

export const AuthRoutes: React.FC<AuthRoutesProps> = ({
  children,
  customRoutes,
}) => {
  return (
    <BrowserRouter>
      <Switch>
        {DEFAULT_ROUTES.map(routeItem => (
          <PublicRoute key={routeItem.path} path={routeItem.path}>
            <routeItem.component />
          </PublicRoute>
        ))}
        {(customRoutes || []).map(routeItem => (
          <Route key={routeItem.path} path={routeItem.path}>
            <routeItem.component />
          </Route>
        ))}
        <PrivateRoute path="/logout">
          <Logout />
        </PrivateRoute>
        <PrivateRoute path="/">{children}</PrivateRoute>
      </Switch>
    </BrowserRouter>
  );
};
