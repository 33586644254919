import React, { useState } from 'react';
import { Avatar, Dropdown, Menu } from 'antd';
import {
  UserOutlined,
  FileTextOutlined,
  LogoutOutlined,
  LoadingOutlined,
} from '@ant-design/icons';

import { useAuth } from './AuthProvider';
import { useTheme } from './ThemeProvider';
import { logout } from './logout';

export type UserMenuProps = { menu?: React.ReactElement };

type UserMenuKey = 'account' | 'documentation' | 'logout';

export const UserMenu: React.FC<UserMenuProps> = ({ menu }) => {
  const [isDropdownVisible, setIsDropdownVisible] = useState<boolean>(false);
  const [isLoggingOut, setIsLoggingOut] = useState<boolean>(false);

  const { user, authHost } = useAuth();
  const { primaryColor } = useTheme();

  const onMenuClick = async (key: UserMenuKey) => {
    switch (key) {
      case 'account':
        window.open(`${authHost}/account`);
        break;
      case 'documentation':
        window.open('https://help.otso.ai/');
        break;
      case 'logout':
        setIsLoggingOut(true);
        await logout();
        break;
      default:
        break;
    }
  };

  const userInitials = user
    ? `${user.firstName.charAt(0)}${user.lastName.charAt(0)}`
    : '';

  return (
    <Dropdown
      visible={isDropdownVisible}
      onVisibleChange={setIsDropdownVisible}
      overlay={
        menu || (
          <Menu onClick={({ key }) => onMenuClick(key as UserMenuKey)}>
            <Menu.Item key="account" icon={<UserOutlined />}>
              My Account
            </Menu.Item>
            <Menu.Item key="documentation" icon={<FileTextOutlined />}>
              Documentation
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item
              key="logout"
              danger
              icon={isLoggingOut ? <LoadingOutlined /> : <LogoutOutlined />}>
              Sign Out
            </Menu.Item>
          </Menu>
        )
      }>
      {user?.photoURL ? (
        <Avatar src={user.photoURL} style={{ cursor: 'pointer' }} />
      ) : (
        <Avatar
          style={{
            cursor: 'pointer',
            ...(primaryColor ? { backgroundColor: primaryColor } : {}),
          }}>
          {userInitials}
        </Avatar>
      )}
    </Dropdown>
  );
};
