import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { useTheme } from './ThemeProvider';
import { useAuth } from './AuthProvider';

export type LogoProps = { height?: number };

type ImgLogoProps = { height: number; src: string };

const ImgLogo: React.FC<ImgLogoProps> = ({ src, height }) => {
  return (
    <Link
      to="/"
      style={{
        height: '100%',
        display: 'inline-flex',
        alignItems: 'center',
      }}>
      <img src={src} alt="logo" height={height} />
    </Link>
  );
};

type ComponentLogoProps = { height: number; logo: React.ReactNode };

const ComponentLogo: React.FC<ComponentLogoProps> = ({ height, logo }) => {
  return (
    <Link
      to="/"
      style={{
        height: '100%',
        display: 'inline-flex',
        alignItems: 'center',
      }}>
      <div
        style={{
          display: 'inline-flex',
          height: height,
          overflow: 'hidden',
        }}>
        {logo}
      </div>
    </Link>
  );
};

export const Logo: React.FC<LogoProps> = ({ height = 30 }) => {
  const [orgLogoSrc, setOrgLogoSrc] = useState<string | null>(null);

  const { logo } = useTheme();

  const { currentOrg, currentOrgStorageRef, authed } = useAuth();

  useEffect(() => {
    const getOrgLogoURL = async () => {
      if (authed && currentOrg?.logo && currentOrgStorageRef) {
        const logoRef = currentOrgStorageRef.child(`system/${currentOrg.logo}`);
        try {
          const url: string = await logoRef.getDownloadURL();
          setOrgLogoSrc(url);
        } catch {
          setOrgLogoSrc(null);
        }
      }
    };
    getOrgLogoURL();
  }, [authed, currentOrg, currentOrgStorageRef]);

  return orgLogoSrc ? (
    <ImgLogo src={orgLogoSrc} height={height} />
  ) : typeof logo === 'string' ? (
    <ImgLogo src={logo} height={height} />
  ) : (
    <ComponentLogo logo={logo} height={height} />
  );
};
