import React from 'react';
import { useLocation, Redirect } from 'react-router-dom';
import { parse } from 'query-string';

import { ResetPassword } from '../ResetPassword';

type Mode = 'resetPassword';

export const ActionCode: React.FC = () => {
  const location = useLocation();

  const { mode }: { mode?: Mode } = parse(location.search);

  switch (mode) {
    case 'resetPassword':
      return <ResetPassword />;
    default:
      return <Redirect to={{ pathname: '/login' }} />;
  }
};
