import React, { useState } from 'react';
import { Form, Input, Button, message } from 'antd';
import { Link } from 'react-router-dom';

import { auth } from '../../firebase-export';
import { AuthPageLayout } from '../../AuthPageLayout';
import { sessionLogin } from '../../api';
import { getFirebaseLoginErrMsg, devLog } from '../../helpers';
import { FirebaseAuthError } from '../../types';

const googleProvider = new auth.GoogleAuthProvider();

export const Login: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isPasswordLoginEnabled, setIsPasswordLoginEnabled] = useState<boolean>(
    false
  );

  const onSessionLogin = async () => {
    try {
      await sessionLogin();
    } catch (error) {
      devLog(error);
    }
  };

  const onLoginError = (error: unknown) => {
    const errMsg = getFirebaseLoginErrMsg(error as FirebaseAuthError);
    if (errMsg) {
      message.error(errMsg);
    }
  };

  const onLoginWithGoogle = async () => {
    try {
      await auth().signInWithPopup(googleProvider);
      await onSessionLogin();
    } catch (error) {
      onLoginError(error);
      setIsLoading(false);
    }
  };

  const onLoginProviderCheck = async (values: { email: string }) => {
    const { email } = values;
    const formattedEmail = email.trim();
    setIsLoading(true);
    try {
      const methods = await auth().fetchSignInMethodsForEmail(formattedEmail);
      if (methods.length === 0) {
        setIsLoading(false);
        setIsPasswordLoginEnabled(true);
      } else if (methods.includes('google.com')) {
        // Login with Google
        await onLoginWithGoogle();
      } else {
        setIsLoading(false);
        setIsPasswordLoginEnabled(true);
      }
    } catch (error) {
      onLoginError(error);
      setIsLoading(false);
    }
  };

  const onEmailAndPasswordLogin = async (values: {
    email: string;
    password: string;
  }) => {
    const { email, password } = values;
    const formattedEmail = email.trim();
    setIsLoading(true);
    try {
      await auth().signInWithEmailAndPassword(formattedEmail, password);
      await onSessionLogin();
    } catch (error) {
      onLoginError(error);
      setIsLoading(false);
    }
  };

  return (
    <AuthPageLayout title="Log in">
      <Form
        layout="vertical"
        onFinish={
          isPasswordLoginEnabled
            ? onEmailAndPasswordLogin
            : onLoginProviderCheck
        }>
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              message: 'Please input your email',
              whitespace: true,
            },
            { type: 'email', message: 'Invalid email' },
          ]}>
          <Input autoFocus placeholder="Email Address" />
        </Form.Item>
        {isPasswordLoginEnabled && (
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: 'Please input your password',
                whitespace: true,
              },
            ]}>
            <Input.Password placeholder="Password" />
          </Form.Item>
        )}
        <Form.Item>
          <Button block loading={isLoading} type="primary" htmlType="submit">
            {isPasswordLoginEnabled ? 'Log in' : 'Continue'}
          </Button>
        </Form.Item>
        <Form.Item style={{ marginBottom: 0 }}>
          <Link to="/forgot-psw" style={{ float: 'right' }}>
            Forgot password
          </Link>
        </Form.Item>
      </Form>
    </AuthPageLayout>
  );
};
